import "./App.css";
import Particle from "./components/Particle";

function App() {
  return (
    <div className="App">
      <header className="App-header">codeforge</header>
      <Particle />
    </div>
  );
}

export default App;
